import React, { Component } from "react";
import { Button, Container } from "react-bootstrap";
import ReactLoading from "react-loading";
import { useHistroy } from "react-router-dom";
import Alert from "@mui/material/Alert";
import { Link } from "react-router-dom";
import { FaHandPointRight } from "react-icons/fa";
import params from "../../util/api_routes/params";
import api from "../../util/api";
import DisplayResults from "./DisplayResults";
import ConfirmInstruction from "../Confirm/ConfirmInstruction";
import CancelInstruction from "../Cancel/CancelInstruction";
import PerformUnrejection from "../Unreject/PerformUnrejection";
import PerformCancellation from "../Cancel/PerformCancellation";
import PerformConfirmation from "../Confirm/PerformConfirmation";
import PerformUnconfirmation from "../Unconfirm/PerformUnconfirmation";
import UnrejectInformation from "../Unreject/UnrejectInformation";
import UnconfirmInstruction from "../Unconfirm/UnconfirmInstruction";
import AdmissionPackage from "./admissionPackage";
import Verified from "../nacte/verified";
import NotVerified from "../nacte/not-verified";
import Results from "../masters-postgraduate/results";

class Selection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // loading: false,
      info_loading: null,
      network_error: false,
      stage: parseInt(localStorage.getItem("stageid")),
      award: parseInt(localStorage.getItem("awardId")),
      final_selection_data: [],
      isQualified: "",
      final_selection_fail: "",
      final_selection_pass: "",
      campus: "",
      program: "",
      status_selection: "",
    };
  }
  componentDidMount() {
    this.FinalSelectionResults();
  }

  FinalSelectionResults = async (e) => {
    // e.preventDefault();
    this.setState({ info_loading: true });
    let payLoad = {
      applicationProfileId: parseInt(localStorage.getItem("id")),
    };
    // this.setState({
    //   status_selection: 2,
    // });
    await api
      .post(params.SELECTION_RESULTS, payLoad)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              final_selection_fail: res.data.data.reasons,
              final_selection_pass: res.data.data,
              status_selection: res.data.data.status,
              campus: res.data.data.campus,
              program: res.data.data.program,
              isQualified: 1,
              info_loading: false,
              network_error: false,
            });
            // console.log(this.state.final_selection.reasons);
            console.log(this.state.status_selection);
            console.log(this.state.campus);
            // console.log(res.data.data);
          } else {
            this.setState({
              // network_error: true,
              info_loading: false,
              // final_selection: [],
            });
          }
        }
      })
      .catch((err) => {
        this.setState({
          // network_error: true,
          info_loading: false,
        });
      });
  };

  render() {
    // const { data } = this.state.final_selection;
    return (
      <React.Fragment>
        <Container fluid>
          <div className="page-content">
            <div className="card">
              <div className="card-header">
                <div className="float-left card-header-title">
                  {/* <i className="fas fa-trophy"></i> */}
                  {/* {""}Congratulation!!!, your application have been submitted
                  successfully */}
                </div>
                <div className="float-right card-header-title">
                  Application Number : {localStorage.getItem("id")}
                </div>
              </div>
              <div className="card-body">
                <div className="block">
                  {this.state.info_loading === true ? (
                    <>
                      {" "}
                      <ReactLoading type="cylon" color="#000" />
                    </>
                  ) : (
                    <>
                      {this.state.network_error === true ? (
                        <div className="row">
                          <div className="col-sm-1"></div>
                          <div className="col-sm-10">
                            <div className="alert alert-warning">
                              <strong>Warning!</strong> You are facing a
                              connection problem, please contact the system
                              administrator OR try later
                            </div>
                          </div>
                          <div className="col-sm-1"></div>
                        </div>
                      ) : (
                        <>
                          {/* <div className="row">
                            {this.state.status_selection === 5 ? (
                              <>
                                <div className="col-sm-12">
                                  <h5>
                                    <Alert severity="success">
                                      Your application is pending for selection.
                                    </Alert>
                                  </h5>
                                </div> */}
                          {this.state.status_selection === 1 ? (
                            <>
                              <div className="row">
                                <div className="col-sm-1">
                                  {/*Display steps*/}
                                </div>
                                <div className="col-sm-5">
                                  {" "}
                                  <CancelInstruction
                                    campus={this.state.campus}
                                    program={this.state.program}
                                  />
                                  {/* <AdmissionPackage /> */}
                                </div>

                                <div className="col-sm-6">
                                  <PerformCancellation />
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {this.state.status_selection === 2 ? (
                                <>
                                  <div className="row">
                                    <div className="col-sm-1">
                                      {/*Display steps*/}
                                    </div>
                                    <div className="col-sm-5">
                                      {" "}
                                      <ConfirmInstruction
                                        campus={this.state.campus}
                                        program={this.state.program}
                                      />
                                    </div>

                                    <div className="col-sm-6">
                                      <PerformConfirmation />
                                    </div>
                                    <div className="col-sm-6">
                                      <PerformCancellation />
                                    </div>
                                    {/* Added after dsicussion 29 Oct 2024 */}
                                    <div className="col-sm-6">
                                      <PerformUnrejection />
                                    </div>
                                    <div className="col-sm-6">
                                      <PerformUnconfirmation />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {this.state.status_selection === 3 ? (
                                    <>
                                      {" "}
                                      <div className="row">
                                        <div className="col-sm-1">
                                          {/*Display steps*/}
                                        </div>
                                        <div className="col-sm-5">
                                          {" "}
                                          <UnconfirmInstruction
                                            campus={this.state.campus}
                                            program={this.state.program}
                                          />
                                          {/* <AdmissionPackage /> */}
                                        </div>

                                        <div className="col-sm-6">
                                          <PerformUnconfirmation />
                                        </div>
                                        <div className="col-sm-6">
                                          <PerformCancellation />
                                        </div>
                                        {/* Added after dsicussion 29 Oct 2024 */}
                                        <div className="col-sm-6">
                                          <PerformUnrejection />
                                        </div>
                                        <div className="col-sm-6">
                                          <PerformConfirmation />
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {this.state.status_selection === 4 ? (
                                        <>
                                          {" "}
                                          <div className="row">
                                            <div className="col-sm-1">
                                              {/*Display steps*/}
                                            </div>
                                            <div className="col-sm-5">
                                              {" "}
                                              <UnrejectInformation
                                                campus={this.state.campus}
                                                program={this.state.program}
                                              />
                                            </div>

                                            <div className="col-sm-6">
                                              <PerformUnrejection />
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          {this.state.status_selection === 6 ? (
                                            <>
                                              <div className="col-sm-12">
                                                <Verified
                                                  campus={this.state.campus}
                                                  program={this.state.program}
                                                />
                                                {/* <AdmissionPackage /> */}
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              <>
                                                {this.state.status_selection ===
                                                7 ? (
                                                  <>
                                                    <div className="col-sm-12">
                                                      <NotVerified
                                                        campus={
                                                          this.state.campus
                                                        }
                                                        program={
                                                          this.state.program
                                                        }
                                                      />
                                                    </div>
                                                  </>
                                                ) : (
                                                  <>
                                                    <>
                                                      {this.state
                                                        .status_selection ===
                                                      5 ? (
                                                        <>
                                                          {this.state.award ==
                                                            4 ||
                                                          this.state.award ==
                                                            5 ||
                                                          this.state.award ==
                                                            3 ? (
                                                            <>
                                                              <div className="col-sm-12">
                                                                <Results
                                                                  campus={
                                                                    this.state
                                                                      .campus
                                                                  }
                                                                  program={
                                                                    this.state
                                                                      .program
                                                                  }
                                                                />
                                                              </div>
                                                            </>
                                                          ) : (
                                                            <>
                                                              <>
                                                                <div className="col-sm-12">
                                                                  <h5>
                                                                    <Alert severity="success">
                                                                      Your
                                                                      application
                                                                      is pending
                                                                      for
                                                                      selection.
                                                                    </Alert>
                                                                  </h5>
                                                                </div>
                                                              </>
                                                            </>
                                                          )}
                                                        </>
                                                      ) : (
                                                        <>
                                                          <>
                                                            <DisplayResults
                                                              taarifa={
                                                                this.state
                                                                  .final_selection_fail
                                                              }
                                                            />
                                                          </>
                                                        </>
                                                      )}
                                                    </>
                                                  </>
                                                )}
                                              </>
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                        //     ) : (
                        //       <>
                        //         <DisplayResults
                        //           taarifa={this.state.final_selection_fail}
                        //         />
                        //       </>
                        //     )}
                        //   </div>
                        // </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}
export default Selection;
